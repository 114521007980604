/* eslint-disable menti-react/filename-convention--jsx */
import { createContext, type ReactNode, useCallback, useContext } from 'react';
import { getLatestPresentationId } from '@mentimeter/presentation-state';
import { trackUser } from '@api/tracking/client';
import { useDispatch, useSeries } from '../../data-mediation';
import { useQuestion } from '../../data-mediation/question';

type PlacementT = 'menu' | 'hotkey';
export interface PresentationActions {
  toggleComments: (placement: PlacementT) => void;
  resetResults: () => void;
  deleteSession: (sessionId: number) => void;
  setActiveSession: (sessionId: number) => void;
  exitPresentation: () => void;
  openMentimote: () => void;
  toggleVoteHeader: () => void;
}

const defaultState: PresentationActions = {
  toggleComments: () => {},
  resetResults: () => {},
  deleteSession: () => {},
  setActiveSession: () => {},
  exitPresentation: () => {},
  openMentimote: () => {},
  toggleVoteHeader: () => {},
};
const context = createContext<PresentationActions>(defaultState);
context.displayName = 'PresentationActionsContext';

const Provider = context.Provider;

export const PresentationActionsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const dispatch = useDispatch();
  const { id, comments_enabled } = useSeries();
  const q = useQuestion();

  const resetResults = () => {
    dispatch({
      type: 'RESET_PRESENTATION',
    });
  };

  const setActiveSession = (id: number) => {
    dispatch({
      type: 'SET_ACTIVE_SESSION',
      payload: { id },
    });
  };

  const deleteSession = (id: number) => {
    dispatch({
      type: 'DELETE_SESSION',
      payload: { id },
    });
  };

  const exitPresentation = useCallback(() => {
    dispatch({ type: 'EXIT' });
  }, [dispatch]);

  const openMentimote = () => {
    dispatch({
      type: 'OPEN_REMOTE',
      payload: { seriesId: id },
    });
  };

  const toggleComments = (placement: PlacementT) => {
    dispatch({
      type: comments_enabled ? 'DISABLE_COMMENTS' : 'ENABLE_COMMENTS',
    });
    trackUser({
      event: 'Toggled CFA',
      properties: {
        context: 'Presentation view',
        placement: placement === 'menu' ? 'Presentation view menu' : `Hotkey E`,
        enabled: !comments_enabled,
        'Presentation id': getLatestPresentationId(),
      },
    });
  };

  const toggleVoteHeader = () => {
    dispatch({
      type: 'SET_QUESTION_SETTING',
      payload: {
        id: q.id,
        key: 'hide_voting_instructions',
        value: !q.hide_voting_instructions,
      },
    });
    trackUser({
      event: 'Changed vote instruction visibility',
      properties: {
        context: 'Presentation view',
        placement: `Hotkey L`,
        instructionType: 'bar',
        hide_join_instructions: !q.hide_voting_instructions,
      },
    });
  };

  return (
    <Provider
      value={{
        toggleComments,
        deleteSession,
        setActiveSession,
        exitPresentation,
        resetResults,
        openMentimote,
        toggleVoteHeader,
      }}
    >
      {children}
    </Provider>
  );
};

export const usePresentationActions = () => useContext(context);
