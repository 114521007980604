import type { SWRConfiguration } from 'swr';
import { core, type QuizResult } from '@mentimeter/http-clients';
import useSWR, { useSWRConfig } from 'swr';
import React from 'react';
import { quizResultCacheKey } from './cache-keys';
import type { WrappedMutator } from './types';

export type QuizResultData = ReturnType<typeof useQuizResult>;

export const useQuizResult = (
  {
    questionId,
    shouldFetch = true,
  }: {
    questionId: string | undefined;
    shouldFetch?: boolean;
    clearCache?: boolean;
  },
  config?: SWRConfiguration<QuizResult[] | undefined>,
) => {
  const cacheKey =
    shouldFetch && questionId ? quizResultCacheKey(questionId) : undefined;
  const { mutate, ...rest } = useSWR<QuizResult[] | undefined>(
    cacheKey,
    async () => {
      if (!questionId) return undefined;
      const { data } = await core().questions.quizResult.get(questionId);
      return data;
    },
    config,
  );

  const fetchResults = React.useCallback(async () => {
    if (!questionId) return undefined;
    const { data } = await core().questions.quizResult.get(questionId);
    mutate(data, { revalidate: false });
    return data;
  }, [mutate, questionId]);

  return { mutate, fetchResults, ...rest };
};

export const useQuizResultMutate = (questionId: string | undefined) => {
  const { mutate } = useSWRConfig();
  return React.useCallback<WrappedMutator<QuizResult[]>>(
    (data, options) => {
      mutate(questionId ? quizResultCacheKey(questionId) : undefined, data, {
        revalidate: false,
        ...options,
      });
    },
    [questionId, mutate],
  );
};
