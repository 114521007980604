/* eslint-disable menti-react/filename-convention--jsx */
import { usePresentation } from '@mentimeter/presentation-state';
import React, {
  createContext,
  type ReactNode,
  useCallback,
  useContext,
  useRef,
} from 'react';
import { trackUser } from '@api/tracking/client';
import { useQuestion } from '../../../data-mediation/question';
import { useDispatch } from '../../dispatcher';

interface TrackingProperties {
  placement: string;
  context: string;
}
function trackToggleBlankScreenOverlay({
  placement,
  context,
}: TrackingProperties) {
  trackUser({
    event: 'Toggled blank screen',
    properties: {
      placement,
      context,
    },
  });
}

export interface BlankScreenContext {
  blankScreenVisible: boolean;
  setBlankScreenVisible: (show: boolean) => void;
  trackToggleBlankScreenOverlay: (
    trackingProperties: TrackingProperties,
  ) => void;
}

const Context = createContext<BlankScreenContext | undefined>(undefined);
Context.displayName = 'BlankScreenContext';
export const Provider = Context.Provider;

export const BlankScreenProvider = ({ children }: { children: ReactNode }) => {
  const { active, id } = useQuestion();
  const initiallyActive = useRef<boolean | undefined>(undefined);
  const dispatch = useDispatch();

  const blankScreenVisible = usePresentation(
    (state) => !!state.state?.blankScreenVisible,
  );

  const setBlankScreenVisibleState = usePresentation(
    (state) => state.actions.setBlankScreenVisible,
  );

  const showBlankScreen = useCallback(() => {
    initiallyActive.current = active;
    dispatch({
      type: 'SET_QUESTION_SETTING',
      payload: {
        id,
        key: 'active',
        value: false,
      },
    });
    setBlankScreenVisibleState(true);
  }, [active, dispatch, id, setBlankScreenVisibleState]);

  const hideBlankScreen = useCallback(() => {
    if (initiallyActive.current !== undefined) {
      dispatch({
        type: 'SET_QUESTION_SETTING',
        payload: {
          id,
          key: 'active',
          value: initiallyActive.current,
        },
      });
      initiallyActive.current = undefined;
    }
    setBlankScreenVisibleState(false);
  }, [dispatch, id, setBlankScreenVisibleState]);

  const setBlankScreenVisible = useCallback(
    (show: boolean) => {
      if (show) {
        showBlankScreen();
      } else {
        hideBlankScreen();
      }
    },
    [showBlankScreen, hideBlankScreen],
  );

  return (
    <Provider
      value={{
        blankScreenVisible,
        setBlankScreenVisible,
        trackToggleBlankScreenOverlay,
      }}
    >
      {children}
    </Provider>
  );
};

export function useBlankScreenState() {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error(
      'useBlankScreenState can only be used inside a `BlankScreenProvider`',
    );
  }
  return context;
}
