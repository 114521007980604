/* eslint-disable menti-react/filename-convention--jsx */
import { SplitProvider as Provider } from '@mentimeter/sfinx';
import { useSplits } from '@mentimeter/splitio';

export const SplitProvider = ({ children }: { children: React.ReactNode }) => {
  const { Live_Identified_Responses, isReady } = useSplits([
    'Live_Identified_Responses',
  ]);

  return (
    <Provider
      value={{
        Live_Identified_Responses,
        isReady,
      }}
    >
      {children}
    </Provider>
  );
};
