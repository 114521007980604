import * as React from 'react';
import { HasQuizResultsContext, QuizResultsContext } from '@mentimeter/quiz';
import {
  useHasQuizResults,
  useQuizPlayersScore,
  useQuizResult,
} from '@mentimeter/core-hooks';
import { isTypeQuizSlide } from 'features/core/shared/questionFilters';
import type { Question, QuestionChoice } from '@mentimeter/http-clients';
import { useQuizPreviewResults } from '@mentimeter/question-modules-preview-results';

// to avoid infinite re-renders when choices are undefined
const CHOICES_FALLBACK: QuestionChoice[] = [];

export const QuizResultProvider = ({
  type,
  questionId,
  children,
  choices = CHOICES_FALLBACK,
  isTestVotesEnabled,
  setQuizResultsHasLoadedForScreenshot,
}: {
  type: Question['type'];
  questionId: string;
  children: React.ReactNode;
  choices: QuestionChoice[];
  isTestVotesEnabled: boolean;
  setQuizResultsHasLoadedForScreenshot?: (value: boolean) => void;
}) => {
  const shouldFetch = isTypeQuizSlide(type) && !isTestVotesEnabled;
  const playerScores = useQuizPlayersScore({
    questionId,
    shouldFetch: shouldFetch && type !== 'quiz',
  });
  const quizResult = useQuizResult({
    questionId,
    shouldFetch: shouldFetch && type === 'quiz',
  });
  const results = type === 'quiz' ? quizResult : playerScores;
  const previewResults = useQuizPreviewResults({
    previewResultsEnabled: isTestVotesEnabled,
    choices,
    questionType: type,
  });
  const hasQuizResults = useHasQuizResults({
    questionId,
    questionType: type,
    shouldFetch,
  });

  const resultsToShow = isTestVotesEnabled ? previewResults : results;

  React.useEffect(() => {
    if (
      setQuizResultsHasLoadedForScreenshot &&
      shouldFetch &&
      !results.isLoading
    ) {
      setQuizResultsHasLoadedForScreenshot(true);
    }
  }, [shouldFetch, results.isLoading, setQuizResultsHasLoadedForScreenshot]);

  return (
    <QuizResultsContext.Provider value={resultsToShow}>
      <HasQuizResultsContext.Provider value={hasQuizResults}>
        {children}
      </HasQuizResultsContext.Provider>
    </QuizResultsContext.Provider>
  );
};
