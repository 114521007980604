import { useCallback, useMemo } from 'react';
import useSWR from 'swr';
import type { SWRConfiguration } from 'swr';
import type { BucketsT, CoreClient } from '@mentimeter/http-clients';
import type { QfaDataMapT, QfaSortingT } from './qfa-fetcher';
import { fetchAllQfa, qfaSortBy } from './qfa-fetcher';

const getList = (
  bucket: BucketsT,
  { ids, data }: QfaDataMapT = { ids: [], data: {} },
  sortBy: QfaSortingT,
) => {
  const idsInBucket = ids?.filter((id) => data[id]?.bucket === bucket) ?? [];
  const idsInBucketSorted = qfaSortBy[sortBy]({
    data,
    ids: idsInBucket,
  });
  return idsInBucketSorted;
};

interface QfaDataSourceT {
  seriesId: string;
  sortBy?: QfaSortingT;
  config?: SWRConfiguration<QfaDataMapT>;
  coreClient: CoreClient;
}

export const useQfaDataSource = ({
  seriesId,
  sortBy = 'time',
  config,
  coreClient,
}: QfaDataSourceT) => {
  const fetcher = useCallback(
    () => fetchAllQfa(coreClient, seriesId),
    [coreClient, seriesId],
  );

  const { data, error, isValidating } = useSWR<QfaDataMapT, any>(
    '/qfa',
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnMount: true,
      ...config,
    },
  );

  const accepted = useMemo(
    () => getList('accepted', data, sortBy),
    [data, sortBy],
  );
  const rejected = useMemo(
    () => getList('rejected', data, sortBy),
    [data, sortBy],
  );
  const pending = useMemo(
    () => getList('pending', data, sortBy),
    [data, sortBy],
  );
  const archived = useMemo(
    () => getList('archived', data, sortBy),
    [data, sortBy],
  );
  const all = useMemo(() => [...accepted, ...archived], [accepted, archived]);

  return {
    data,
    error,
    isValidating,
    accepted,
    rejected,
    pending,
    archived,
    all,
  };
};
