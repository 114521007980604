import type { QfaDataT, CoreClient } from '@mentimeter/http-clients';

export interface QfaDataMapT {
  ids: Array<number>;
  data: Record<string | number, QfaDataT>;
}

/**
 * Fetch all QFA on all pages
 */
export const fetchAllQfa = async (
  client: CoreClient,
  id: null | string,
): Promise<QfaDataMapT> => {
  // First page is 1
  let page = 1;
  // Total count is the total number of qfa questions returned from api.
  let totalCount = -1;
  let data: Array<QfaDataT> = [];
  while (data.length < totalCount || totalCount === -1) {
    const { data: responseData } = await client.qfa.get.all(id ?? '', page);
    totalCount = responseData.total_count;
    data = data.concat(responseData.data);
    page++;
  }

  // Remove all questions with profanity
  const filteredQuestions = data.filter((q) => !q.is_profanity);

  const dataMap = filteredQuestions.reduce(
    (prev, q) => ({ ...prev, [q.id]: q }),
    {},
  );
  const ids = filteredQuestions.map(({ id }) => id);
  return { ids, data: dataMap };
};

const toUnixTimestamp = (s: string | undefined) =>
  (s === undefined ? Date.now() : new Date(s).getTime()) / 1000;

const dsSortByTime = (dataMap: QfaDataMapT) =>
  dataMap.ids.sort(
    (a, b) =>
      toUnixTimestamp(
        dataMap.data[b]?.accepted_at || dataMap.data[b]?.created_at,
      ) -
      toUnixTimestamp(
        dataMap.data[a]?.accepted_at || dataMap.data[a]?.created_at,
      ),
  );

const dsSortByUpvotes = (dataMap: QfaDataMapT) =>
  dataMap.ids.sort(
    (a, b) => (dataMap.data[b]?.upvotes ?? 0) - (dataMap.data[a]?.upvotes ?? 0),
  );

export type QfaSortingT = 'time' | 'upvotes';
export const qfaSortBy = {
  upvotes: dsSortByUpvotes,
  time: dsSortByTime,
};
