/* eslint-disable menti-react/filename-convention--jsx */
import {
  createContext,
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import screenfull from 'screenfull';
import { MentiError } from '@mentimeter/errors/sentry';
import { usePresentationActions } from '../../misc/presentation-actions';

interface FullscreenContext {
  /**
   * Whether screenfull reports itself as disabled.
   */
  isDisabled: boolean;
  isFullscreen: boolean;
  enterFullscreen: () => void;
  /**
   * Exit fullscreen without navigating to the editor.
   */
  exitFullscreen: () => Promise<void>;
  /**
   * Navigate to the editor, exiting fullscreen if needed.
   */
  exitPresentationAndFullscreen: () => Promise<void>;
}

const Context = createContext<FullscreenContext | null>(null);

export const useFullscreen = () => {
  const context = useContext(Context);
  if (context === null) {
    throw new MentiError(
      'Tried to use FullscreenContext outside of the provider',
      {
        feature: 'sfinx',
      },
    );
  }
  return context;
};

/**
 * Syncs the screenfull state with a React state. Provides the following ways to interact with fullscreen:
 * - Navigating to the editor when the user exits fullscreen via the Esc key or via the browser menus. This is achieved through an `useEffect` on mount that executes `handleFullscreenChange`.
 * - `exitFullscreen`: Exits fullscreen without navigating to the editor
 * - `exitFullscreenAndPresentation`: Navigates to the editor, exiting fullscreen if necessary.
 *
 * Needs to be a descendant of PresentationActionsContext.
 * @param children
 * @constructor
 */
export const FullscreenProvider = ({ children }: { children: ReactNode }) => {
  const { exitPresentation } = usePresentationActions();
  const [isFullscreen, setIsFullscreen] = useState(screenfull.isFullscreen);

  const handleFullscreenChange = useCallback(() => {
    if (screenfull.isEnabled) {
      setIsFullscreen(screenfull.isFullscreen);
      if (!screenfull.isFullscreen) {
        exitPresentation();
      }
    }
  }, [exitPresentation]);

  useEffect(() => {
    if (screenfull.isEnabled) {
      screenfull.on('change', handleFullscreenChange);
    }
    return () => {
      if (screenfull.isEnabled) {
        screenfull.off('change', handleFullscreenChange);
      }
    };
  }, [handleFullscreenChange]);

  /** We just want to exit fullscreen, and not go back to the editor.
   *  Therefore, we disable the event handler, exit fullscreen, and then
   *  re-enable the handler.
   */
  const exitFullscreen = useCallback(async () => {
    if (screenfull.isEnabled) {
      screenfull.off('change', handleFullscreenChange);
      await screenfull.exit().catch(() => null);
      setIsFullscreen(false);
      // it's important to wait for a frame to reattach the handler, otherwise
      // it gets executed for the change we just caused.
      requestAnimationFrame(() => {
        screenfull.on('change', handleFullscreenChange);
      });
    }
  }, [handleFullscreenChange]);

  const exitPresentationAndFullscreen = useCallback(async () => {
    await exitFullscreen();
    exitPresentation();
  }, [exitFullscreen, exitPresentation]);

  const enterFullscreen = useCallback(() => {
    if (screenfull.isEnabled) {
      screenfull.request().catch(() => null);
      setIsFullscreen(true);
    }
  }, []);

  const isDisabled = !screenfull.isEnabled;

  const value = useMemo(
    () => ({
      isDisabled,
      isFullscreen,
      enterFullscreen,
      exitFullscreen,
      exitPresentationAndFullscreen,
    }),
    [
      enterFullscreen,
      exitFullscreen,
      exitPresentationAndFullscreen,
      isDisabled,
      isFullscreen,
    ],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
