import { useEffect } from 'react';
import has from 'lodash/has';
import {
  initialize as initializeLocalize,
  addTranslationForLanguage,
  getTranslate,
} from 'lib/react-localize-redux/locale';
import type {
  LocaleState,
  TranslateFunction,
} from 'lib/react-localize-redux/locale';
// constants
import {
  DEFAULT,
  LANGUAGES,
  ENGLISH,
  SWEDISH,
  SPANISH,
  FRENCH,
  DUTCH,
  POLISH,
  FINNISH,
  ESTONIAN,
  GERMAN,
  HINDI,
  ICELANDIC,
  ITALIAN,
  PORTUGUESE,
  PORTUGUESE_BRAZIL,
  PUNJABI,
  RUSSIAN,
  HUNGARIAN,
  CROATIAN,
  JAPANESE,
  NORWEGIAN,
  DANISH,
  ROMANIAN,
  TURKISH,
  LITHUANIAN,
  INDONESIAN,
  CHINESE_TRADITIONAL,
  CHINESE_SIMPLIFIED,
  CZECH,
  UKRAINIAN,
  SLOVENIAN,
  WELSH,
  CATALAN,
  TAMIL,
  SERBIAN_LATIN,
} from '@mentimeter/sfinx/languages/constants';
// languages
import englishTranslation from '@mentimeter/sfinx/languages/data/en.json';
import swedishTranslation from '@mentimeter/sfinx/languages/data/sv.json';
import spanishTranslation from '@mentimeter/sfinx/languages/data/es.json';
import frenchTranslation from '@mentimeter/sfinx/languages/data/fr.json';
import polishTranslation from '@mentimeter/sfinx/languages/data/pl.json';
import finnishTranslation from '@mentimeter/sfinx/languages/data/fi.json';
import dutchTranslation from '@mentimeter/sfinx/languages/data/nl.json';
import estonianTranslation from '@mentimeter/sfinx/languages/data/et.json';
import germanTranslation from '@mentimeter/sfinx/languages/data/de.json';
import hindiTranslation from '@mentimeter/sfinx/languages/data/hi.json';
import icelandicTranslation from '@mentimeter/sfinx/languages/data/is.json';
import italianTranslation from '@mentimeter/sfinx/languages/data/it.json';
import portugueseTranslation from '@mentimeter/sfinx/languages/data/pt.json';
import portugueseBrazilTranslation from '@mentimeter/sfinx/languages/data/br.json';
import punjabiTranslation from '@mentimeter/sfinx/languages/data/pa.json';
import russianTranslation from '@mentimeter/sfinx/languages/data/ru.json';
import hungarianTranslation from '@mentimeter/sfinx/languages/data/hu.json';
import croatianTranslation from '@mentimeter/sfinx/languages/data/hr.json';
import japaneseTranslation from '@mentimeter/sfinx/languages/data/ja.json';
import norwegianTranslation from '@mentimeter/sfinx/languages/data/no.json';
import danishTranslation from '@mentimeter/sfinx/languages/data/da.json';
import romanianTranslation from '@mentimeter/sfinx/languages/data/ro.json';
import turkishTranslation from '@mentimeter/sfinx/languages/data/tr.json';
import lithuanianTranslation from '@mentimeter/sfinx/languages/data/lt.json';
import indonesianTranslation from '@mentimeter/sfinx/languages/data/id.json';
import chineseTraditionalTranslation from '@mentimeter/sfinx/languages/data/zh.json';
import chineseSimplifiedTranslation from '@mentimeter/sfinx/languages/data/zhs.json';
import czechTranslation from '@mentimeter/sfinx/languages/data/cs.json';
import ukrainianTranslation from '@mentimeter/sfinx/languages/data/uk.json';
import slovenianTranslation from '@mentimeter/sfinx/languages/data/sl.json';
import welshTranslation from '@mentimeter/sfinx/languages/data/cy.json';
import catalanTranslation from '@mentimeter/sfinx/languages/data/ca.json';
import tamilTranslation from '@mentimeter/sfinx/languages/data/ta.json';
import serbianLatinTranslation from '@mentimeter/sfinx/languages/data/srl.json';
import { useAppDispatch } from 'features/core/editor/state';
import type { AppDispatch } from 'features/core/editor/state/types';
import type { SeriesWithSlideDeck as Series } from '@mentimeter/presentation-schema/api-types-overrides';

const LANGUAGE_CODES = LANGUAGES.map(({ code }) => code);

const initTranslations = (dispatch: AppDispatch, defaultLang: string) => {
  const defaultLanguage = defaultLang || DEFAULT;
  dispatch(initializeLocalize(LANGUAGE_CODES, { defaultLanguage }));
  dispatch(addTranslationForLanguage(englishTranslation, ENGLISH));
  dispatch(addTranslationForLanguage(spanishTranslation, SPANISH));
  dispatch(addTranslationForLanguage(swedishTranslation, SWEDISH));
  dispatch(addTranslationForLanguage(frenchTranslation, FRENCH));
  dispatch(addTranslationForLanguage(polishTranslation, POLISH));
  dispatch(addTranslationForLanguage(finnishTranslation, FINNISH));
  dispatch(addTranslationForLanguage(dutchTranslation, DUTCH));
  dispatch(addTranslationForLanguage(estonianTranslation, ESTONIAN));
  dispatch(addTranslationForLanguage(germanTranslation, GERMAN));
  dispatch(addTranslationForLanguage(hindiTranslation, HINDI));
  dispatch(addTranslationForLanguage(icelandicTranslation, ICELANDIC));
  dispatch(addTranslationForLanguage(italianTranslation, ITALIAN));
  dispatch(addTranslationForLanguage(portugueseTranslation, PORTUGUESE));
  dispatch(
    addTranslationForLanguage(portugueseBrazilTranslation, PORTUGUESE_BRAZIL),
  );
  dispatch(addTranslationForLanguage(punjabiTranslation, PUNJABI));
  dispatch(addTranslationForLanguage(russianTranslation, RUSSIAN));
  dispatch(addTranslationForLanguage(hungarianTranslation, HUNGARIAN));
  dispatch(addTranslationForLanguage(croatianTranslation, CROATIAN));
  dispatch(addTranslationForLanguage(japaneseTranslation, JAPANESE));
  dispatch(addTranslationForLanguage(norwegianTranslation, NORWEGIAN));
  dispatch(addTranslationForLanguage(danishTranslation, DANISH));
  dispatch(addTranslationForLanguage(romanianTranslation, ROMANIAN));
  dispatch(addTranslationForLanguage(turkishTranslation, TURKISH));
  dispatch(addTranslationForLanguage(lithuanianTranslation, LITHUANIAN));
  dispatch(addTranslationForLanguage(indonesianTranslation, INDONESIAN));
  dispatch(
    addTranslationForLanguage(
      chineseTraditionalTranslation,
      CHINESE_TRADITIONAL,
    ),
  );
  dispatch(
    addTranslationForLanguage(chineseSimplifiedTranslation, CHINESE_SIMPLIFIED),
  );
  dispatch(addTranslationForLanguage(czechTranslation, CZECH));
  dispatch(addTranslationForLanguage(ukrainianTranslation, UKRAINIAN));
  dispatch(addTranslationForLanguage(slovenianTranslation, SLOVENIAN));
  dispatch(addTranslationForLanguage(welshTranslation, WELSH));
  dispatch(addTranslationForLanguage(catalanTranslation, CATALAN));
  dispatch(addTranslationForLanguage(tamilTranslation, TAMIL));
  dispatch(addTranslationForLanguage(serbianLatinTranslation, SERBIAN_LATIN));
};

const noop: any = () => {};

export function getTranslateHelper(locale: LocaleState): TranslateFunction {
  if (has(locale, 'languages.0') && has(locale, 'options.defaultLanguage')) {
    return getTranslate(locale);
  }
  return noop;
}

export const useInitTranslations = (language: Series['language']) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    initTranslations(dispatch, language);
  }, [dispatch, language]);
};
