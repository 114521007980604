import type { Question } from '@mentimeter/http-clients';
import { useQuizPlayersScore } from './use-quiz-players-score';
import { useQuizResult } from './use-quiz-result';

export const useHasQuizResults = ({
  questionId,
  questionType,
  shouldFetch,
}: {
  questionId: string | undefined;
  questionType: Question['type'] | undefined;
  shouldFetch: boolean;
}) => {
  const { data: playerScores } = useQuizPlayersScore({
    questionId,
    shouldFetch: !!questionId && shouldFetch && questionType !== 'quiz',
  });
  const { data: result } = useQuizResult({
    questionId,
    shouldFetch: !!questionId && shouldFetch && questionType === 'quiz',
  });
  if (questionType === 'quiz') {
    return Boolean(result?.some((r) => Boolean(r.responses)));
  } else {
    return Boolean(playerScores?.some((r) => Boolean(r.score.question)));
  }
};
