/* eslint-disable menti-react/filename-convention--jsx */
import React from 'react';
import { ErrorBoundary } from '@mentimeter/errors/ErrorBoundary';
import { Box, Button, Text } from '@mentimeter/ragnar-ui';
import {
  CountdownProvider,
  PresentationActionsProvider,
  SlideActionsProvider,
} from '../data-mediation';
import { FullscreenProvider } from '../data-mediation/fullscreen';
import Results from './Results';
import { BlankScreenProvider } from './overlays/blank-screen';

export const InternalProviders = ({
  children,
  renderFallbackOnError = true,
}: {
  children: React.ReactNode;
  renderFallbackOnError?: boolean;
}) => {
  return (
    <ErrorBoundary
      feature="live"
      fallback={
        renderFallbackOnError ? (
          <Box
            alignItems="center"
            justifyContent="center"
            position="absolute"
            width="100%"
            height="100%"
          >
            <Text mt="3">Something went wrong...</Text>
            <Button
              size="large"
              mt="1"
              aria-label="Reload page"
              onClick={() => window.location.reload()}
            >
              Click to reload page
            </Button>
          </Box>
        ) : undefined
      }
    >
      <Results>
        <BlankScreenProvider>
          <CountdownProvider>
            <PresentationActionsProvider>
              <FullscreenProvider>
                <SlideActionsProvider>{children}</SlideActionsProvider>
              </FullscreenProvider>
            </PresentationActionsProvider>
          </CountdownProvider>
        </BlankScreenProvider>
      </Results>
    </ErrorBoundary>
  );
};
